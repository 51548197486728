<template>
  <div class="grainSubsidy">
    <el-dialog title="供应信息详情" :before-close="handleClose2" :visible.sync="dialogVisible2" width="40%">
      <el-form ref="form" label-width="160px">
        <el-form-item label="农服商名称:">
          {{ currentItem.companyName }} <span v-if="currentItem.city">({{ currentItem.city }})</span>
        </el-form-item>
        <el-form-item label="经营场所地址:">
          {{ currentItem.address }}
        </el-form-item>
        <el-form-item label="联系人:">
          {{ currentItem.contactMan }}
        </el-form-item>
        <el-form-item label="联系电话:"> {{ currentItem.phone }} </el-form-item>
        <el-form-item label="意向区域:">{{
          currentItem.intentionRegion
          }}</el-form-item>
        <el-form-item label="作物类型:">
          {{ currentItem.serviceScope }}
        </el-form-item>
        <el-form-item label="托管环节:">
          {{ currentItem.operScope }}
        </el-form-item>
        <el-form-item label="可接管土地面积:">
          {{ currentItem.serviceArea ? currentItem.serviceArea : 0 }}
          亩</el-form-item>
        <el-form-item label="已接管土地面积:">
          {{ currentItem.serviceAreaOlde ? currentItem.serviceAreaOlde : 0 }}
          亩</el-form-item>
        <el-form-item label="经营场所照片:">
          <el-image style="width: 100px; height: 100px" :src="item" v-for="(item, index) in currentItem.companyPhoto"
            :key="index" :preview-src-list="currentItem.companyPhoto">
          </el-image>
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image style="width: 100px; height: 100px" :src="item" v-for="(item, index) in currentItem.xczp"
            :key="index" :preview-src-list="currentItem.xczp">
          </el-image>
        </el-form-item>
        <el-form-item label="其他说明:">{{ currentItem.remark }}</el-form-item>
      </el-form>
      <!-- <div class="btn" style="text-align: center">
        <el-button @click="back">关闭</el-button>
        <el-button @click="collect">收藏</el-button>
        <el-button @click="Online">在线沟通</el-button>
        <el-button @click="goout" type="primary">我要托管</el-button>
      </div> -->
    </el-dialog>
    <!-- 种粮大户补贴申请 -->
    <el-dialog title="兴村通平台社会化服务记录" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <table-page :data="tableData" :columns="columns" :pagination="false">
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="showDetail(scope.row)">详情</el-button>

        </template>
      </table-page>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <div class="subsidyBox">
      <el-form :inline="true" :model="form" label-position="left" :rules="formRules" ref="ruleForm">
        <div class="LoanApplication">

          <!-- 基础信息 -->
          <div class="BasicInformation">
            <div class="title">
              <p>基础信息</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="申请人：">
                  <el-input v-model="form.applyUser" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="身份证/机构代码：" :disabled="true">
                  <el-input v-model="form.idCard" placeholder=""></el-input>
                </el-form-item>
                <el-form-item :disabled="true" label="银行账户：">
                  <el-input v-model="form.bankCard" placeholder=""></el-input>
                </el-form-item>
                <el-form-item v-if="identityObj.identityTypeCode!=3&&identityObj.identityTypeCode!=2" label="申报主体类型：">
                  <el-select :disabled="true" v-model="form.declareType" style="width: 200px">
                    <el-option v-for="(item, index) in types" :key="index" :value="item.value"
                      :label="item.label"></el-option></el-select>
                </el-form-item>
                <div class="LegalPersonIDCard">
                  <label class="el-form-item__label" style="width: 110px">
                    法定代表人身份证照片：
                  </label>
                  <el-image style="width: 150px; height: 80px;margin-left: 5px; " :src="
                                        baseUrl + 'admin/file/get?ossFilePath=' + userCardFrontImg
                                      ">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/icon/imgError.png" />
                    </div>
                  </el-image>
                  <el-image style="width: 150px; height: 80px;margin-left: 5px;" :src="
                                        baseUrl + 'admin/file/get?ossFilePath=' + userCardBackImg
                                      ">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>

              <div class="Right">
                <el-form-item label="联系电话：">
                  <el-input v-model="form.phone" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="地址：">
                  <!-- <el-cascader style="width: 190px" ref="cascader" v-model="form.address" :props="props"
                    :options="options" :disabled="true" :show-all-levels="false" @change="regionChange"></el-cascader> -->
                  <el-input v-model="form.address" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="开户行：">
                  <el-input v-model="form.bankName" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="申报村镇：">
                  <el-cascader :disabled="true" style="width: 190px" ref="cascader" v-model="form.areaCode"
                    :props="props" :options="options" :show-all-levels="false" @change="regionChange"></el-cascader>
                </el-form-item>
                <div class="BusinessLicense" v-if="businessLicense">
                  <label class="el-form-item__label" style="width: 105px">
                    营业执照：
                  </label>
                  <el-image :src="baseUrl + 'admin/file/get?ossFilePath=' + businessLicense">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>
              <div class="backBtn">
                <el-button type="primary" @click="back">返回</el-button>
              </div>
            </div>
          </div>

          <!-- 补贴面积 -->
          <div class="SubsidizedArea">
            <div class="title">
              <p>补贴面积</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="承包面积：" prop="income">
                  <el-input v-model="form.contractArea" :disabled="true" placeholder="" type="number"></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <!-- <div class="tips">
                  <span> (其中新村通平台服务20亩)</span>
                  <span style="color:#64ADDC;padding-left:20px" @click="dialogVisible = true">查看详情</span>
                </div> -->
                <!-- <p>元</p> -->
                <!-- <span style="padding-top:10px">
                元
              </span>
            <p>{{ BigYearIncome }}</p> -->
              </div>
              <div class="Center">
                <el-form-item label="租种面积" prop="YearManagement">
                  <el-input :disabled="true" v-model="form.rentalArea" type="number" placeholder=""></el-input>

                  <div class="unit">亩 <el-button type="primary" @click="showadd = true">查看</el-button></div>
                </el-form-item>
                <div class="tips">
                  <span> (其中兴村通平台服务{{platformServiceArea}}亩)</span>
                  <span style="color:#64ADDC;padding-left:20px" @click="dialogVisible = true">查看详情</span>
                </div>
              </div>
              <div class="Right">
                <el-form-item label="补贴面积合计：" prop="YearManagement">
                  <el-input :disabled="true" v-model="form.totalSubsidyArea" type="number" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
              </div>
            </div>
          </div>

          <!-- 种植信息 -->
          <div class="PlantingArea">
            <div class="title">
              <p>作物种植面积</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="小麦种植面积">
                  <el-input v-model="form.wheatArea" :disabled="true" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>

                <el-form-item label="种植面积合计">
                  <el-input :disabled="true" v-model="form.totalPlantingArea" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>

              </div>
              <div class="Center">
                <el-form-item label="玉米种植面积：">
                  <el-input v-model="form.cornArea" :disabled="true" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <el-form-item label="水稻种植面积：">
                  <el-input :disabled="true" v-model="form.riceArea" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
              </div>

              <!-- <div class="Right">

            </div> -->
            </div>
          </div>
          <!-- 承包耕地证明 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>承包耕地证明</p>
            </div>
            <div class="textContent">

              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" :disabled="true"
                name="files" :auto-upload="false" :file-list="fileList" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in certificateContractPicUrl" :key="index">

                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>
          <!-- 耕地流转合同 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>承包耕地证明</p>
            </div>
            <div class="textContent">

              <el-upload ref="pic1" multiple class="upload-demo" :disabled="true" :limit="6" :action="uploadUrl"
                name="files" :auto-upload="false" :file-list="fileList" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in landTransferContractPicUrl" :key="index">
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>
          <!-- 其他资料 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>承包耕地证明</p>
            </div>
            <div class="textContent">

              <el-upload ref="pic1" multiple class="upload-demo" :disabled="true" :limit="6" :action="uploadUrl"
                name="files" :auto-upload="false" :file-list="fileList" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in otherFiles" :key="index">
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>

        </div>
      </el-form>
    </div>
    <el-dialog title="租种详情" :visible.sync="showadd" width="40%" style="text-align: center;">
      <el-table :data="form.twActivitySubsidyLargeGrainGrowersAreaList" style="width: 100%">
        <el-table-column prop="areaCode" label="所在地区" align="center">
          <template slot-scope="scope">
            <el-cascader ref="cascader" :disabled="true" v-model="scope.row.areaCode" :props="props" :options="options"
              :show-all-levels="false" @change="regionChange1(scope.row,scope.$index)"></el-cascader>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="亩数" align="center">
          <template slot-scope="scope">
            <el-input :disabled="true" v-model="scope.row.area" placeholder="请输入亩数"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button disabled type="danger" size="mini" @click="deleteRow(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- <el-button style="margin-top: 20px;" @click="addRow">添加一行</el-button> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="showadd = false">取 消</el-button>
        <el-button type="primary" @click="rentalAreaIpt">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { getAreaTree } from "@/api/certificate";
  import { removeEmptyChildren } from "@/js/utils/util";
  import TablePage from "@/components/table/table-page";
  import { grainSubsidyColumn } from '../../tableColumn.js'
  import { getXmxxDetail } from '@/api/exchange'
  import { getMySubsidyById, selectLandArea, selectLargeGrainGrowersDetail } from '@/api/subsidy'
  export default {
    data() {
      return {
        form: {
          applyUser: '',//申请人
          declareAreaName: '',//申报村镇
          idCard: '',//身份证/机构代码
          bankCard: '',//行账户
          declareType: '',//申报主体类型
          phone: '',//联系电话
          bankName: '',//开户行
          address: '',//地址名称
          areaCode: '',//行政区划
          contractArea: '',//承包面积
          rentalArea: '',//租种面积
          totalSubsidyArea: '',  // 补贴面积合计
          wheatArea: '',//小麦种植面积
          totalPlantingArea: '',//种植面积合计
          cornArea: '',//玉米种植面积
          riceArea: '',// 水稻种植面积
          twActivitySubsidyLargeGrainGrowersAreaList: [
            {
              area: "",
              areaCode: "",
              areaName: "",
              declareId: ""
            }
          ],
          //添加平台服务面积

        }, //这是输入基础信息
        types: [{
          label: '种粮农户',
          value: '1'
        },
        {
          label: '农民专业合作社',
          value: '2'
        },
        {
          label: '家庭农场',
          value: '3'
        },
        {
          label: '土地股份合作社',
          value: '4'
        },
        {
          label: '农业产业化龙头企业',
          value: '5'
        },
        {
          label: '村集体经济组织',
          value: '6'
        },
        {
          label: '其他',
          value: '7'
        }
        ],
        props: {
          label: "areaName",
          children: "children",
          value: "id",
          fullPathName: "fullPathName",
          checkStrictly: true,
          emitPath: false,
        },
        columns: grainSubsidyColumn,
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        options: [],
        tableData: [],
        dialogVisible: false,
        dialogVisible2: false,
        baseUrl: window.globalUrl.BASE_API,
        fileList: [], //上传图片的列表,
        businessLicense: "", // 存储营业执照
        userCardFrontImg: "", // 存储人面像图片
        userCardBackImg: "", // 存储国徽像图片
        formRules: {}, //表单验证规则
        certificateContractPicUrl: [],//承包耕地证明
        landTransferContractPicUrl: [],//	耕地流转合同
        otherFiles: [],//其他材料
        platformServiceArea: '',//平台服务面积
        showadd: false,
        currentItem: [],

      }
    },
    created() {
      this.getMySubsidyById()
    },
    mounted() {
      this.getRegions()
    },
    computed: {
      ...mapGetters(["identityObj"]),

    },
    components: {
      TablePage,
    },
    methods: {
      handleClose2() {
        this.dialogVisible2 = false
      },
      // 租种面积-
      rentalAreaIpt() {
        this.showadd = false;
        this.form.rentalArea = this.form.twActivitySubsidyLargeGrainGrowersAreaList.reduce((accumulator, item) => {
          return accumulator + (parseFloat(item.area) || 0);
        }, 0);

        console.log(this.form.rentalArea, '租种面积');

        const contractArea = parseFloat(this.form.contractArea) || 0;
        const rentalArea = parseFloat(this.form.rentalArea) || 0;
        this.form.totalSubsidyArea = contractArea + rentalArea;
        console.log(this.form.totalSubsidyArea, '总补贴面积');
      },
      //种粮大户租种面积查询
      async selectLandArea(areaCode) {
        console.log(areaCode, 'areaCode')
        let params = {
          areaCode: areaCode,
          identityId: this.identityObj.identityId
        }
        let res = await selectLandArea(params)
        let types = JSON.parse(localStorage.getItem("types"));
        // console.log(res.data.landAreas, 'res.data.landAreas')
        let newData = res.data.landAreas.map((item, index) => {
          let cqlxbh = types.find(its => its.bh == item.cqlxbh).name
          console.log(cqlxbh, 'cqlxbh')
          return {
            cqlxbh2: cqlxbh,
            ...item,
          }
        })
        console.log(newData, 'newData')
        this.tableData = newData
        this.platformServiceArea = res.data.landAreaMu

      },
      //租种面积的查询
      async selectLargeGrainGrowersDetail(id) {
        let params = {
          declareId: id
        }
        let res = await selectLargeGrainGrowersDetail(params)
        if (res.code == 0) {
          this.form.twActivitySubsidyLargeGrainGrowersAreaList = res.data.subsidyLargeGrainGrowersVo.twActivitySubsidyLargeGrainGrowersAreaList

        }
      },
      // 回显数据
      initFrom(obj) {
        this.form.address = obj.largeGrainGrowersDto.address
        console.log(this.form.address, '查看this.form.address')
        this.selectLandArea(obj.largeGrainGrowersDto.areaCode)
        this.form.bankName = obj.largeGrainGrowersDto.bankName
        this.form.applyUser = obj.largeGrainGrowersDto.applyUser
        this.form.areaCode = obj.largeGrainGrowersDto.areaCode,
          this.form.idCard = obj.largeGrainGrowersDto.idCard
        this.form.bankCard = obj.largeGrainGrowersDto.bankCard
        this.form.declareType = obj.largeGrainGrowersDto.declareType
        this.userCardFrontImg = obj.largeGrainGrowersDto.legalIdcardPicFront ? obj.largeGrainGrowersDto.legalIdcardPicFront : ''
        this.userCardBackImg = obj.largeGrainGrowersDto.legalIdcardPicBack ? obj.largeGrainGrowersDto.legalIdcardPicBack : ''
        this.form.phone = obj.largeGrainGrowersDto.phone
        this.form.areaName = obj.largeGrainGrowersDto.areaName
        this.form.declareAreaName = obj.largeGrainGrowersDto.declareAreaName
        this.form.businessLicense = obj.largeGrainGrowersDto.businessLicense
        this.form.contractArea = obj.largeGrainGrowersDto.contractArea
        this.form.rentalArea = obj.largeGrainGrowersDto.rentalArea
        this.form.totalSubsidyArea = obj.largeGrainGrowersDto.totalSubsidyArea
        this.form.wheatArea = obj.largeGrainGrowersDto.wheatArea
        this.form.totalPlantingArea = obj.largeGrainGrowersDto.totalPlantingArea
        this.form.cornArea = obj.largeGrainGrowersDto.cornArea
        this.form.riceArea = obj.largeGrainGrowersDto.riceArea
        this.certificateContractPicUrl = obj.largeGrainGrowersDto.certificateContractPicUrl ? JSON.parse(obj.largeGrainGrowersDto.certificateContractPicUrl) : []//承包耕地证明
        this.landTransferContractPicUrl = obj.largeGrainGrowersDto.landTransferContractPicUrl ? JSON.parse(obj.largeGrainGrowersDto.landTransferContractPicUrl) : []//	耕地流转合同
        this.otherFiles = obj.largeGrainGrowersDto.otherFiles ? JSON.parse(obj.largeGrainGrowersDto.otherFiles) : ''//其他材料
      },
      // 查看详情
      async showDetail(row) {
        console.log(row, '查看是什么')

        // let res = await getXmxxDetail({ xmbh: row.xmbh })
        // this.dialogVisible2 = true
        // this.currentItem = res.data
        let xmbh = row.xmbh
        // let url = this.baseUrl
        // let newUrl = this.baseUrl.replace(/^\/api\/|\/api\/$/g, '');
        // // console.log(baseUrlWithoutApi);
        // window.open(newUrl + '#/home/projectDetail?c=' + xmbh);
          window.open(window.location.origin + `#/home/projectDetail?c=` + xmbh, "_blank");
      },
      //  详情页面数据
      async getMySubsidyById() {

        let params = {
          id: this.$route.query.id
        }
        let res = await getMySubsidyById(params)
        if (res.code == 0) {

          this.initFrom(res.data)
          this.selectLargeGrainGrowersDetail(res.data.id)
        }
      },
      // 关闭弹窗
      handleClose() {
        this.dialogVisible = false
      },
      // 获取行政区树
      async getRegions() {
        let param = {
          level: "5",
          // parentCode:this.topSelectAreacode
        };
        let res = await getAreaTree(param);

        let { code, data, msg } = res;
        if (code == 0) {
          this.options = removeEmptyChildren(data);
        } else {
          this.$message.error(msg);
        }
      },
      //上传图片
      back() {
        this.$router.go(-1); // 返回上一级路由
      },
      //行政区做切换
      regionChange() {
        let node = this.$refs.address.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        console.log(node.hasChildren);
        console.log(this.areaHasChild, "切换行政区");
        console.log(this.identityObj.areaCode);
        this.form.areaCode = node.value;
        this.form.address = node.pathLabels.join("");
        setTimeout(() => {
          this.$refs.form.validateField("address");
        }, 100);
      },
      //附件下载功能
      async AddFiles(item) {
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = "/api/admin/file/get?ossFilePath=" + item.imgUrl;
        dom.setAttribute("download", item.FilesName);
        document.body.appendChild(dom);
        dom.click();
      },


      handleRemove(file) {
        // 处理文件的移除逻辑
        this.fileList = this.fileList.filter(item => item.imgUrl !== file.imgUrl);
      },

      handleChangeUp(file, fileList) {
        // 处理文件更改逻辑
        this.fileList = fileList;
      }
    }
  }
</script>

<style lang="less" scoped>
  .grainSubsidy {
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;

    .subsidyBox {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;

      margin-top: 50px;

      .LoanApplication {
        width: 100%;
        height: 100%;

        .backBtn {
          width: 100%;
          height: auto;
          display: flex;
        }

        /*基础信息*/
        .BasicInformation {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;

            .Left {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 130px;
                  height: 40px;
                }
              }
            }

            .Right {
              width: 40%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 124px;
                  height: 40px;
                }
              }
            }

            .backBtn {
              width: 10%;
              display: flex;
              flex-direction: column;
            }
          }
        }

        /*补贴面积*/
        .SubsidizedArea {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;
            width: 100%;
            display: flex;
            flex-flow: wrap;

            .Left {
              width: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }

              }

              .tips {
                padding-top: 0px;
                // width: 100px;
                // background: #000;
                // padding-top: 1000px;
                /* padding-left: 10px; */
              }
            }

            .Center {
              width: 50%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }

              }

              .tips {
                padding-left: 10px;

              }
            }

            .Right {
              width: 50%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-form-item__label {
                  width: 120px !important;
                }
              }


              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }
          }
        }

        /*种植信息*/
        .PlantingArea {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;
            flex-flow: wrap;

            .Left {
              width: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }

              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Center {
              width: 50%;
              display: flex;
              flex-direction: column;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }

              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Right {
              width: 50%;
              display: flex;
              flex-direction: column;

              /deep/.el-form-item {
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-form-item__label {
                  width: 120px !important;
                }
              }


              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 100px;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }
          }
        }

        /* 身份证照片 */
        .LegalPersonIDCard {
          display: flex;

          /deep/.el-form-item__label {
            padding-right: 0px;
          }

          /deep/.el-image {
            img {
              width: 150px;
              height: 80px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }
        /* 营业执照*/
        .BusinessLicense {
          display: flex;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding-left: 20px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }


        /*其他附件*/
        .OtherAccessories {
          border: solid 1px #ccc;
          display: flex;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;

              i {
                color: red;
              }
            }
          }

          .textContent {
            width: 100%;
            height: 120px;
            display: flex;
            border: solid 1px #ccc;

            .Item {
              width: 150px;
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: center;
              position: relative;
              padding: 10px;

              .el-image {
                width: 150px;
                height: 85px;
                background: #e6e3e3;

                img {
                  width: 200px;
                  height: 50px;
                }

                i {
                  font-size: 50px;
                  font-weight: bold;
                  margin-top: 20px;
                }
              }

              span {
                margin-top: 5px;
              }

              .del {
                width: 20px;
                height: 20px;
                border: solid 1px black;
                border-radius: 50px;
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 5000;
                text-align: center;
                line-height: 20px;
              }
            }

            .Item:hover {
              .del {
                display: block;
              }
            }

            .upload-demo {
              i {
                font-size: 50px;
                font-weight: bold;
                margin-top: 30px;
              }
            }
          }
        }
      }
    }

  }
</style>